<template>
  <div class="productPlayer">
    <div class="container">
      <div class="playerHead">
        <p class="name">আমাদের তৈরি পোলো-শাট নিজ চোখে দেখে নিন</p>
      </div>
      <div>
        <video controls>
          <source src="../../src/assets/videoPlay2.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleProductAppShowProductPlayer",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style>
.productPlayer {
  margin-top: 50px;
}
.productPlayer .playerHead .name {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #000000;
}
.productPlayer video {
  width: 100%;
  border-radius: 10px;
}
</style>